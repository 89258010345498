import React from "react";
import { modalTypeEnum } from "./StepsFields";

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const WelcomeDetails = ({ onNext, modalType }) => {
  const applyInstructions = [
    "Apply online in only 10 minutes.",
    "Receive response in 48 hours",
  ];

  return (
    <div className="welcome-details p-4">
      <h3 className="welcome-header">
        Welcome to Swift {modalTypeEnum[modalType]} Services
      </h3>
      <p className="welcome-content">
        {`Thank you for wishing to apply for a credit account with Swift ${capitalizeFirstLetter(
          modalTypeEnum[modalType].toLowerCase()
        )}
        Services.`}
      </p>

      <h3 className="welcome-header">Who We Are</h3>
      <p className="welcome-content">
        Swift Transport is a leading, independent, and wholly Australian-owned
        company specializing in transport and logistics. We proudly serve
        clients across Sydney, Melbourne, Brisbane, Adelaide, and Perth.
      </p>

      <ul className="welcome-content">
        <li>
          Market Leader: Swift is a leading player in the Metropolitan transport
          and logistics market in Australia, servicing major cities like Sydney,
          Melbourne, Brisbane, Adelaide, and Perth.
        </li>
        <li>
          Independent and Australian-Owned: Swift is an independent, wholly
          Australian-owned private company, known for its pioneering role in the
          transport and logistics industry.
        </li>
        <li>
          Diverse Fleet: The company operates a diverse mix of over 300
          company-owned vehicles, including rigid flat tops, skel trailers,
          retractables, sideloaders, genset equipped trailers and A Doubles.
        </li>
        <li>
          Commitment to Excellence: Swift is dedicated to providing high-quality
          transport services, continually adapting to the changing logistics
          landscape to meet client needs.
        </li>
      </ul>

      <h3 className="welcome-header">Our Services</h3>
      <ul className="welcome-content">
        <li>
          All manner of FCL handling, direct deliveries, underbond, fumigation,
          storage, unpack and redelivery and much more.
        </li>
        <li>
          At Swift, we are dedicated to innovation and excellence. Our team of
          professional drivers, sales, administration, and operational personnel
          are focused on meeting our clients’ requirements with integrity,
          passion, and a commitment to safety.
        </li>
      </ul>
      <h3 className="welcome-header">Why Choose Us?</h3>
      <ul className="welcome-content">
        <li>
          Market Leader: Largest logistics provider for major freight forwarders
          and shipping lines in Sydney.
        </li>
        <li>
          Biggest fleet, the absolute best operations staff and more combined
          experience than any other company.
        </li>
        <li>
          Swift build strong, long lasting relationships that endure all types
          of constraints and challenges on a daily basis
        </li>
      </ul>

      <h3 className="welcome-header">Join Us</h3>
      <p className="welcome-content">
        Partner with us for all your transport and logistics needs.
        <br />
        Experience the Swift difference.
      </p>

      {applyInstructions.map((instruction, index) => (
        <div className="mb-3 welcome-content" key={index}>
          {instruction}
        </div>
      ))}

      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={onNext}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default WelcomeDetails;
