import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import { dataURLtoFile } from "../../../utils/dataURLtoFile";
import { FormField, TextBox } from "../../Form";
import { useGetSfCustomerSignature } from "../srfCustomerAPI";

function Signature({ prev = {}, onSubmit }) {
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    control,
    register,
  } = useFormContext();
  const signatureCanvasRef = useRef(null);
  const [showFile, setShowFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const customerId = getValues("customerId") || null;
  const signRequired = getValues("signRequired");
  const { data, isLoading } = useGetSfCustomerSignature(customerId);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyDirectors",
  });

  if (signRequired) {
    setValue("signUrl", "");
  }

  let today = new Date().toISOString().slice(0, 10);
  if (!getValues("signatureDate")) {
    setValue("signatureDate", today);
  }
  useEffect(() => {
    const defaultSignature = getValues("signature");
    const signURL = getValues("signUrl");
    if (defaultSignature instanceof File) {
      const url = URL.createObjectURL(defaultSignature);
      setImageSrc(url);
      return () => URL.revokeObjectURL(url);
    } else if (typeof signURL === "string") {
      if (signURL.startsWith("CustomerProfile/")) {
        if (!isLoading) {
          let base64data = data;
          if (base64data.startsWith("data:image/png;base64,")) {
            base64data = base64data.slice("data:image/png;base64,".length);
          }
          let file = dataURLtoFile(
            `data:image/png;base64,${base64data}`,
            "signature.png"
          );
          setImageSrc(URL.createObjectURL(file));
          return () => URL.revokeObjectURL(file);
        } else {
          setImageSrc(defaultSignature);
        }
      }
    }
  }, [data, getValues, isLoading]);

  const clearSignature = (event) => {
    event.preventDefault();
    signatureCanvasRef.current.clear();
    setValue("signature", null);
    setShowFile(null);
    setImageSrc(null);
  };

  const saveSignature = async (event) => {
    event.preventDefault();
    if (!signatureCanvasRef.current.isEmpty()) {
      const signatureImage = signatureCanvasRef.current.toDataURL("image/png");
      const signatureFile = dataURLtoFile(signatureImage, "signature.png");
      setValue("signature", signatureFile);
      setShowFile(signatureFile);
      setValue("signatureDate", today);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue("signature", file);
      setShowFile(file);
    }
  };

  const [isError, setIsError] = useState(null);

  useEffect(() => {
    if (errors?.signature) return setIsError(errors?.signature?.message);
    return setIsError(null);
  }, [errors, imageSrc]);

  useEffect(() => {
    if (showFile) {
      const url = URL.createObjectURL(showFile);
      setImageSrc(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [showFile]);

  const submit = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Signature</h3>
      <div className="my-3">
        <div className="my-3">
          {fields.map((field, index) => (
            <div key={field.id} className="mb-3">
              <FormField
                className="Field_label"
                title="Company Director Name"
                id={`companyDirectors.${index}.directorName`}
                ratio={[12, 12]}
              >
                <TextBox
                  type="text"
                  id={`companyDirectors.${index}.directorName`}
                  register={register}
                  errors={errors}
                  placeholder="eg. John"
                  className="py-2"
                  getError={(error) =>
                    error?.companyDirectors?.[index]?.directorName
                  }
                />
              </FormField>
              <FormField
                className="Field_label"
                title="Company Director Address"
                id={`companyDirectors.${index}.directorAddress`}
                ratio={[12, 12]}
              >
                <TextBox
                  type="text"
                  id={`companyDirectors.${index}.directorAddress`}
                  register={register}
                  errors={errors}
                  placeholder="eg. block 10, NSW, Sydney"
                  className="py-2"
                  getError={(error) =>
                    error?.companyDirectors?.[index]?.directorAddress
                  }
                />
              </FormField>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  Remove
                </button>
              </div>
              <hr />
            </div>
          ))}
          <div className="text-center mb-4">
            <button
              type="button"
              className="btn"
              onClick={() =>
                append({
                  directorName: "",
                  directorAddress: "",
                })
              }
            >
              + Add Directors
            </button>
          </div>
        </div>
        <FormField
          className="Field_label"
          title="Authorize Representative Name"
          required
          id="signaturePersonName"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="signaturePersonName"
            register={register}
            errors={errors}
            placeholder="eg. John"
            className="py-2"
            getError={(error) => error?.signaturePersonName}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Position"
          required
          id="signaturePersonPosition"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="signaturePersonPosition"
            register={register}
            errors={errors}
            placeholder="eg. Accountant"
            className="py-2"
            getError={(error) => error?.signaturePersonPosition}
          />
        </FormField>
        {(!getValues("signUrl") || signRequired) && (
          <>
            <FormField
              className="Field_label"
              title="Digital Signature"
              required
              id="signature"
              ratio={[12, 12]}
            >
              <SignatureCanvas
                ref={signatureCanvasRef}
                penColor="black"
                canvasProps={{
                  height: 200,
                  className: `signature-canvas ${
                    errors?.signature?.message ? "is-invalid" : ""
                  }`,
                }}
              />
              <div className="signature-btn">
                <button
                  onClick={clearSignature}
                  className="form-control btn btn-outline-dark"
                >
                  Clear Signature
                </button>
                <Button onClick={saveSignature} className="form-control">
                  Save Signature
                </Button>
              </div>
            </FormField>
            <p>or</p>
            <FormField
              className="Field_label"
              title="Upload Signature Image"
              id="signatureFile"
              ratio={[12, 12]}
            >
              <input
                type="file"
                id="signatureFile"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
            </FormField>
          </>
        )}
        {imageSrc && (
          <div className="d-flex flex-column">
            <Form.Label>Preview</Form.Label>
            {loading && (
              <div className="my-5 d-flex justify-content-center align-items-center">
                <Spinner animation="border" />
              </div>
            )}
            <img
              src={imageSrc}
              width="100%"
              height={200}
              alt="Signature Preview"
              onLoad={handleImageLoad}
              style={loading ? { display: "none" } : {}}
            />
          </div>
        )}
        {isError && <p className="text-danger">{isError}</p>}
        <FormField
          className="Field_label"
          title="Date"
          id="date"
          required
          ratio={[1, 4]}
        >
          {getValues("signatureDate")}
        </FormField>
      </div>
      <div className="form-btn">
        {!signRequired && (
          <button type="button" className="credit-form-btn" onClick={prev}>
            Back
          </button>
        )}
        <button type="submit" className="credit-form-btn">
          Submit
        </button>
      </div>
    </form>
  );
}

export default Signature;
