import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormField, TextBox } from "../../Form";
import { useEmailOrPhoneExits } from "../srfCustomerAPI";
import { Spinner } from "react-bootstrap";

function ClientDetails({ onNext, responseData = null }) {
  const [copyAs, setCopyAs] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { mutate, isLoading } = useEmailOrPhoneExits();
  const oldEmail = responseData?.emailAddress.toLowerCase();

  const submit = async (data) => {
    if (oldEmail === data.emailAddress.toLowerCase()) {
      return onNext();
    }
    mutate(
      { email: data.emailAddress },
      {
        onSuccess: () => {
          return onNext();
        },
      }
    );
  };
  const handleCheckbox = (e) => {
    setCopyAs(e.target.checked);
    if (!copyAs) {
      setValue(
        "personalInfo.postalAddress.address",
        getValues("businessInfo.businessAddress.address")
      );
      setValue(
        "personalInfo.postalAddress.suburb",
        getValues("businessInfo.businessAddress.suburb")
      );
      setValue(
        "personalInfo.postalAddress.state",
        getValues("businessInfo.businessAddress.state")
      );
      setValue(
        "personalInfo.postalAddress.postCode",
        getValues("businessInfo.businessAddress.postCode")
      );
    } else {
      setValue("personalInfo.postalAddress.address", "");
      setValue("personalInfo.postalAddress.suburb", "");
      setValue("personalInfo.postalAddress.state", "");
      setValue("personalInfo.postalAddress.postCode", "");
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Company Details</h3>
      <div className="my-3">
        <FormField
          className="Field_label"
          title="Company Name"
          required
          id="companyName"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="companyName"
            register={register}
            errors={errors}
            placeholder="eg. MICROSOFT SOLUTION"
            className="py-2"
            getError={(error) => error?.personalInfo?.companyName}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="ABN"
          id="personalInfo.abn"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="personalInfo.abn"
            register={register}
            errors={errors}
            placeholder="eg. MICROSOFT SOLUTION"
            className="py-2"
            getError={(error) => error?.personalInfo?.abn}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Trading Name"
          id="personalInfo.tradingName"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="personalInfo.tradingName"
            register={register}
            errors={errors}
            placeholder="eg. MICROSOFT SOLUTION"
            className="py-2"
            getError={(error) => error?.personalInfo?.tradingName}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Business Address"
          required
          id="businessInfo.businessAddress.address"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="businessInfo.businessAddress.address"
            register={register}
            errors={errors}
            placeholder="Address"
            className="py-2"
            getError={(error) => error?.businessInfo?.businessAddress?.address}
          />
        </FormField>
        <div className="d-flex gap-5">
          <div className="w-100">
            <FormField
              className="Field_label"
              title="Suburb"
              required
              id="businessInfo.businessAddress.suburb"
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id="businessInfo.businessAddress.suburb"
                register={register}
                errors={errors}
                placeholder="eg. NSW"
                className="py-2"
                getError={(error) =>
                  error?.businessInfo?.businessAddress?.suburb
                }
              />
            </FormField>
          </div>
          <div className="w-100">
            <FormField
              className="Field_label"
              title="State"
              required
              id="businessInfo.businessAddress.state"
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id="businessInfo.businessAddress.state"
                register={register}
                errors={errors}
                placeholder="eg. NSW"
                className="py-2"
                getError={(error) =>
                  error?.businessInfo?.businessAddress?.state
                }
              />
            </FormField>
          </div>
          <div className="w-100">
            <FormField
              className="Field_label"
              title="Postcode"
              required
              id="businessInfo.businessAddress.postCode"
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id="businessInfo.businessAddress.postCode"
                register={register}
                errors={errors}
                placeholder="eg. 2000"
                className="py-2"
                getError={(error) =>
                  error?.businessInfo?.businessAddress?.postCode
                }
              />
            </FormField>
          </div>
        </div>
        <div className="d-flex gap-3">
          <input
            className="form-check-input border-black "
            type="checkbox"
            checked={copyAs}
            onChange={handleCheckbox}
          />
          <p style={{ textTransform: "initial" }}>
            Use the business address for the postal address
          </p>
        </div>
        <FormField
          className="Field_label"
          title="Postal Address"
          required
          id="personalInfo.postalAddress.address"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="personalInfo.postalAddress.address"
            register={register}
            errors={errors}
            placeholder="eng. Address"
            className="py-2"
            getError={(error) => error?.personalInfo?.postalAddress?.address}
          />
        </FormField>
        <div className="d-flex gap-5">
          <div className="w-100">
            <FormField
              className="Field_label"
              title="Suburb"
              required
              id="personalInfo.postalAddress.suburb"
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id="personalInfo.postalAddress.suburb"
                register={register}
                errors={errors}
                placeholder="eg. NSW"
                className="py-2"
                getError={(error) => error?.personalInfo?.postalAddress?.suburb}
              />
            </FormField>
          </div>
          <div className="w-100">
            <FormField
              className="Field_label"
              title="State"
              required
              id="personalInfo.postalAddress.state"
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id="personalInfo.postalAddress.state"
                register={register}
                errors={errors}
                placeholder="eg. NSW"
                className="py-2"
                getError={(error) => error?.personalInfo?.postalAddress?.state}
              />
            </FormField>
          </div>
          <div className="w-100">
            <FormField
              className="Field_label"
              title="Postcode"
              required
              id="personalInfo.postalAddress.postCode"
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id="personalInfo.postalAddress.postCode"
                register={register}
                errors={errors}
                placeholder="eg. 2000"
                className="py-2"
                getError={(error) =>
                  error?.personalInfo?.postalAddress?.postCode
                }
              />
            </FormField>
          </div>
        </div>
        <FormField
          title="Telephone Main"
          id="phoneNo"
          className="Field_label"
          required={true}
          ratio={[12, 12]}
        >
          <div className="input-group">
            <span className="input-group-text" id="phoneNo">
              +61
            </span>
            <TextBox
              type="text"
              id="phoneNo"
              register={register}
              errors={errors}
              placeholder="eg. 9834587237"
              className="py-2"
            />
          </div>
        </FormField>
        <FormField
          className="Field_label"
          title="Mobile"
          id="alternatePhoneNo"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="alternatePhoneNo"
            register={register}
            errors={errors}
            placeholder="eg. 9834587237"
            className="py-2"
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Email"
          required
          id="emailAddress"
          ratio={[12, 12]}
        >
          <TextBox
            type="email"
            id="emailAddress"
            register={register}
            errors={errors}
            placeholder="eg. example@example.com"
            className="py-2"
          />
        </FormField>

        <FormField
          className="Field_label"
          title="Fax"
          id="personalInfo.fax"
          ratio={[12, 12]}
        >
          <TextBox
            type="number"
            id="personalInfo.fax"
            register={register}
            errors={errors}
            placeholder="eg. 123456789"
            className="py-2"
            getError={(error) => error?.personalInfo?.fax}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Credit Limit"
          id="personalInfo.creditLimit"
          ratio={[12, 12]}
        >
          <div className="input-group">
            <span className="input-group-text" id="personalInfo.creditLimit">
              $
            </span>
            <TextBox
              type="text"
              id="personalInfo.creditLimit"
              register={register}
              errors={errors}
              placeholder="eg. $90000"
              className="py-2"
              getError={(error) => error?.personalInfo?.creditLimit}
            />
          </div>
        </FormField>
        <FormField
          className="Field_label"
          title="Email Addresses For Invoices"
          id="emailAddressesForInvoices"
          ratio={[12, 12]}
        >
          <TextBox
            type="email"
            id="emailAddressesForInvoices"
            register={register}
            errors={errors}
            placeholder="eg. invoice@example.com"
            className="py-2"
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Email Addresses For Account Statement"
          id="emailAddressesForAccountStatement"
          ratio={[12, 12]}
        >
          <TextBox
            type="email"
            id="emailAddressesForAccountStatement"
            register={register}
            errors={errors}
            placeholder="eg. statement@example.com"
            className="py-2"
          />
        </FormField>
      </div>
      <div className="form-btn">
        <button type="submit" className="credit-form-btn">
          {isLoading ? <Spinner /> : "Next"}
        </button>
      </div>
    </form>
  );
}

export default ClientDetails;
