import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiFileDownloadLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CREDIT_APPLICATION } from "../../constants/routes";
import { errorToast } from "../../utils/toastFunc";
import Spinner from "../Spinner/Spinner";
import "./CreditApplication.scss";
import MultiStepProgressBar from "./ProgressBar/ProgressBar";
import { schemas } from "./schemas";
import { useGetSfCustomer, useSfCustomer } from "./srfCustomerAPI";
import AccountDetails from "./Steppers/AccountDetails";
import BankDetails from "./Steppers/BankDetails";
import ClientDetails from "./Steppers/ClientDetails";
import ConditionsOfCarriage from "./Steppers/ConditionOfCarriage.jsx/ConditionsOfCarriage";
import Confirmation from "./Steppers/Confirmation";
import PaymentMethod from "./Steppers/PaymentMethod";
import PreviewDetails from "./Steppers/PreviewDetails";
import Signature from "./Steppers/Signature";
import {
  CreditAppDefaultValues,
  eachModalLogo,
  modalTypeEnum,
} from "./Steppers/StepsFields";
import ThankYou from "./Steppers/ThankYou";
import TradeDetails from "./Steppers/TradeDetails";
import WelcomeDetails from "./Steppers/WelcomeDetails";

const CreditApplicationForm = () => {
  const navigate = useNavigate();
  const isViewMode = window.location.pathname.includes("view");
  const isCustomerAdmin = window.location.pathname.includes(
    "finance-application"
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [secretToken, setSecretToken] = useState(null);
  const [isAdminProcessed, setIsAdminProcessed] = useState(false);
  const { modalType, id } = useParams();
  const { mutate } = useSfCustomer(setSecretToken, isCustomerAdmin);
  const { data: responseData, error, isLoading } = useGetSfCustomer(id);

  const methods = useForm({
    defaultValues: CreditAppDefaultValues,
    resolver: yupResolver(
      schemas[currentStep - 1]({
        signUrl: responseData?.signUrl,
        isAdminProcessed,
      })
    ),
    reValidateMode: "onChange",
  });

  const ModalType = modalType || window.location.pathname.split("/")[2];

  useEffect(() => {
    if (responseData) {
      methods.reset({
        ...responseData,
      });
      if (isViewMode) return setCurrentStep(9);
      if (responseData?.adminStatus === "PROCESSED" && !isCustomerAdmin) {
        setIsAdminProcessed(true);
        return setCurrentStep(11);
      }
      setCurrentStep(2);
    }
  }, [responseData, methods, isViewMode, isAdminProcessed, isCustomerAdmin]);

  const onSubmit = async (data) => {
    if (!ModalType) {
      return errorToast({ mes: "Modal type is required" });
    }
    const { createdDate, lastModifiedBy, lastModifiedDate, ...cleanedData } =
      data;
    cleanedData.creditApplicationModalType = modalTypeEnum[ModalType];
    const formData = new FormData();
    formData.append("signature", cleanedData.signature);
    formData.append("jsonString", JSON.stringify(cleanedData));
    mutate(
      { formData },
      {
        onSuccess: () => {
          if (isAdminProcessed) {
            setCurrentStep(10);
            return;
          }
          setCurrentStep((steps) => steps + 1);
        },
      }
    );
  };

  const onNext = () => {
    setCurrentStep((steps) => steps + 1);
  };

  const prev = () => {
    if (currentStep > 2) {
      setCurrentStep((step) => step - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <WelcomeDetails onNext={onNext} modalType={ModalType} />;
      case 2:
        return (
          <ClientDetails
            prev={prev}
            onNext={onNext}
            responseData={responseData}
          />
        );
      case 3:
        return <AccountDetails prev={prev} onNext={onNext} />;
      case 4:
        return <TradeDetails prev={prev} onNext={onNext} />;
      case 5:
        return <PaymentMethod prev={prev} onNext={onNext} />;
      case 6:
        return <BankDetails prev={prev} onNext={onNext} />;
      case 7:
        return (
          <ConditionsOfCarriage
            prev={prev}
            onNext={onNext}
            isCustomerAdmin={isCustomerAdmin}
          />
        );
      case 8:
        return <Signature prev={prev} onSubmit={onSubmit} id={id} />;
      case 9:
        return <PreviewDetails isCustomerAdmin={isCustomerAdmin} />;
      case 10:
        return <ThankYou />;
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <div
        className="credit-application"
        style={
          isAdminProcessed || !(currentStep > 1 && currentStep < 9)
            ? { justifyContent: "center" }
            : {}
        }
      >
        {id && isLoading ? (
          <Spinner />
        ) : error ? (
          <Alert variant="danger" className="container mt-5">
            {error.message}
          </Alert>
        ) : (
          <div>
            {currentStep > 1 && currentStep < 10 && !isViewMode && (
              <div className="progressBar">
                <MultiStepProgressBar currentStep={currentStep} />
              </div>
            )}
            <div className="form-container">
              <Container
                className="credit-application-form"
                fluid
                style={isViewMode ? { width: "80%" } : {}}
              >
                {currentStep === 9 && isViewMode && (
                  <div className="form-btn justify-content-between mx-1">
                    <button
                      type="button"
                      className="credit-form-btn d-flex align-items-center justify-content-center"
                      style={{ width: "15%" }}
                      onClick={() => navigate(-1)}
                    >
                      <IoMdArrowRoundBack style={{ marginRight: "8px" }} />
                      Back
                    </button>
                    <button
                      type="button"
                      className="credit-form-btn d-flex align-items-center justify-content-center"
                      style={{ width: "20%" }}
                      onClick={() => window.print()}
                    >
                      Download
                      <RiFileDownloadLine style={{ marginLeft: "4px" }} />
                    </button>
                  </div>
                )}
                {isAdminProcessed && !isViewMode && currentStep !== 10 && (
                  <div className="form-btn justify-content-end mx-1">
                    <Link
                      to={`/view/credit-application/${id}`}
                      className="unstyled-link d-flex align-items-center justify-content-center"
                      style={{ width: "fit-content" }}
                    >
                      <button
                        type="button"
                        className="credit-form-btn px-3"
                        style={{ width: "fit-content" }}
                      >
                        View Application
                      </button>
                    </Link>
                  </div>
                )}
                {!(currentStep > 1 && currentStep < 9) && (
                  <nav className="navbar">
                    <img
                      src={eachModalLogo[ModalType]}
                      width="150"
                      height="90"
                      className="d-inline-block align-top me-5"
                      alt="React Bootstrap logo"
                    />
                  </nav>
                )}
                {/* For confirmation through mail */}
                {isAdminProcessed && !isViewMode ? (
                  currentStep === 10 ? (
                    <ThankYou />
                  ) : (
                    <>
                      <Confirmation onSubmit={onSubmit} />
                      {methods.getValues("signRequired") === true && (
                        <Signature onSubmit={onSubmit} id={id} />
                      )}
                    </>
                  )
                ) : (
                  <>
                    {renderStep()}
                    <div className="form-btn">
                      {currentStep === 9 && !isViewMode && (
                        <>
                          <Link
                            to={`${CREDIT_APPLICATION}/${ModalType}/${secretToken}`}
                            className="unstyled-link"
                          >
                            <button type="button" className="credit-form-btn">
                              Edit
                            </button>
                          </Link>
                          <button
                            type="button"
                            className="credit-form-btn"
                            onClick={onNext}
                          >
                            Finish
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </Container>
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default CreditApplicationForm;
