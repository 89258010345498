import React from "react";
import "./InBoundContainer.scss";
import logo from "../../../images/swift-transport-logo.png";
import img1 from "../../../images/Inbound-container/1.jpg";
import img2 from "../../../images/Inbound-container/2.jpg";
import img3 from "../../../images/Inbound-container/3.jpg";
import img4 from "../../../images/Inbound-container/4.jpg";
import img5 from "../../../images/Inbound-container/5.jpg";
import img6 from "../../../images/Inbound-container/6.jpg";

function InBoundContainerPdfCode() {
  return (
    <div className="inbound-container">
      <div className="d-flex justify-content-center mb-5">
        <img width={220} height={120} src={logo} alt="swift-logo" />
      </div>
      <h4 className="header">Inbound Container Work Instruction</h4>
      <ul>
        <li>Dropping Inbound Containers</li>
        <li>
          Dropping Inbound Containers Press intercom or swipe with access card
          if provided with one.
        </li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img1} alt="1" />
        </div>
        <li>
          Drive to allocated dock as provided by DHL / Maersk. NOTE: If Dock
          already has a container on the allocated dock this container Must be
          removed off and can be parked along the fence until it is taken away.
        </li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img2} alt="2" />
        </div>
        <li>
          Before backing onto the allocated dock check to ensure the Pitbull is
          down and the path to the dock is clear.
        </li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img3} alt="3" />
        </div>
        <li>
          Once backed onto the dock check to ensure container is centred
          correctly and sits about 50 — 100mm off the dock which will allow dock
          lever to be raised and rest on the containers floor.
        </li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img4} alt="4" />
        </div>
        <li>
          Once in the correct position driver is then able to wind down the
          trailer legs and unhook prime mover from trailer.
        </li>
        <li style={{ fontWeight: 600, textDecoration: "underline" }}>
          Picking Up Export & Empty Containers
        </li>
        <li>Press intercom or swipe with access card if provided with one.</li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img1} alt="1" />
        </div>
        <li>
          Before Reversing under container that has been requested to be
          collected, check that there is a green light, if RED or no light lite
          up go to office to check the status of container.
        </li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img5} alt="5" />
        </div>
        <li>
          Once given the OK to remove container off the dock check back end of
          container underneath to ensure Pitbull is in the down position just
          inase of failed light of Pitbull hasn’t released. As sometimes
          depending on position of container the trailer might need to roll back
          a little so Pitbull can be released.
        </li>
        <div className="d-flex justify-content-center my-2 inbound-img">
          <img width={450} height={400} src={img6} alt="6" />
        </div>
        <li>
          Once all checks have been completed driver is free to hook up to
          trailer and take it away.
        </li>
      </ul>
      <h5>
        <strong>Note:</strong>
      </h5>
      <h5>
        <strong>Site Hrs:</strong> 24/7 (Swipe card access ONLY) or Mon — Fri
        6am — 8pm
      </h5>
      <ol>
        <h5>Site Contact Details:</h5>
        <li>Terry — 0434 664 127 (1pm — 12am)</li>
        <li>Charissa — 0423 935 480 (6am — 2pm) </li>
        <li>Adrian — 0403 670 950 (9am — 5pm)</li>
      </ol>
      <h5>
        <strong>Address:</strong> 23-107 ERSKINE PARK ROAD ERSKINE PARK NSW 2759
      </h5>
    </div>
  );
}

export default InBoundContainerPdfCode;
