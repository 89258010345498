import { useNavigate } from "react-router-dom";
import "../FinanceApplication.scss";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export const CommonBoxWithHeader = ({ title, children }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="finance-back-btn">
        <button type="button" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack style={{ marginRight: "8px" }} />
          Back
        </button>
      </div>
      <div className="dashboard">
        <div className="dashboard_header">
          <h3 className="">{title}</h3>
        </div>
        <div className="dashboard_bottom">{children}</div>
      </div>
    </>
  );
};

export const ButtonWithTooltip = ({ variant, onClick, icon, tooltipText }) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-${tooltipText}`}>{tooltipText}</Tooltip>}
  >
    <Button variant={variant} onClick={onClick}>
      {icon}
    </Button>
  </OverlayTrigger>
);
