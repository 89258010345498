import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineFileDownloadOff } from "react-icons/md";
import { downloadFileFromBlob, fetchService } from "../utils/api";
import config from "../constants/config";
import { errorToast } from "../utils/toastFunc";
import { useState } from "react";

export const DownloadDocumentButton = ({
  docName,
  userId,
  url = null,
  fileName = null,
  headers = {},
}) => {
  const [loading, setLoading] = useState(false);

  const URL = url ? url : config.downloadInductionDoc + docName;

  const handleDownload = async () => {
    setLoading(true);
    fetchService({
      method: "GET",
      url: URL,
      isFile: true,
      headers,
    })
      .then((response) => {
        if (response.status === 200) return response.blob();
        else errorToast({ mes: "Error downloading the file" });
      })
      .then((stream) => {
        downloadFileFromBlob(stream, fileName ? fileName : docName);
      })
      .catch((error) => {
        errorToast({
          mes: error?.message?.split?.(":")?.[1] ?? "Something went wrong!",
        });
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  if (!docName)
    return (
      <button
        // disabled
        className="btn btn-error no-document-btn"
        style={{ color: "red", width: "90%" }}
      >
        <MdOutlineFileDownloadOff />
      </button>
    );

  return (
    <div className="w-100">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-Download`}>Download</Tooltip>}
      >
        <Button
          variant="info"
          style={{ width: "-webkit-fill-available" }}
          onClick={handleDownload}
        >
          {loading ? (
            <Spinner
              style={{ width: "16px", height: "16px" }}
              animation="border"
              variant="primary"
            />
          ) : (
            <AiOutlineDownload />
          )}
        </Button>
      </OverlayTrigger>
    </div>
  );
};
