import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";
import TableComp from "../../../components/Table";
import config from "../../../constants/config";
import { DownloadDocumentButton } from "../../../context/DownloadDocumentButton";
import { fetchService, postFile } from "../../../utils/api";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../utils/toastFunc";
import { NoteCell } from "../../SurchargeMaintenance/components/CommonCell";
import { ColumnFilter } from "../../UserManagement/UsersDisplay";
import { ButtonWithTooltip } from "./Common";

function DocumentModal({ showModal }) {
  const [files, setFiles] = useState([]);
  const customerId = showModal?.customerId || "";
  const url = config.AdminSfCustomer.download + customerId;
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ["customer", customerId],
    queryFn: async () => {
      const response = await fetchService({
        url: config.AdminSfCustomer.getDocument + customerId,
      });
      console.log(response.data);

      return response.data;
    },
  });

  const deleteDocumentMutate = useMutation({
    mutationFn: async ({ docId }) => {
      await fetchService({
        url: `${config.AdminSfCustomer.deleteDocument}${customerId}/${docId}`,
        method: "DELETE",
      });
    },
    onSuccess: () => {
      successToast({ mes: "Document Deleted" });
    },
    onError: (err) => {
      errorToast({ mes: err.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer", customerId],
      });
    },
  });

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "*",
    multiple: true,
  });

  const mutation = useMutation({
    mutationFn: async ({ formData }) =>
      await postFile(
        config.baseUrl + config.AdminSfCustomer.uploadDocument + customerId,
        formData
      ),
    onSuccess: () => {
      successToast({ mes: "Files uploaded successfully!" });
      setFiles([]);
    },
    onError: (error) => {
      console.error("Upload failed:", error);
      errorToast({ mes: "Upload failed. Please try again." });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer", customerId],
      });
    },
  });

  const handleUpload = () => {
    if (files.length === 0) {
      warningToast({ mes: "Please select at least one file to upload." });
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("documents", file);
    });

    mutation.mutate({ formData });
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Document Name",
        accessor: "fileName",
        Filter: ColumnFilter,
        disableFilters: true,
        maxWidth: 30,
        minWidth: 20,
        width: 20,
        Cell: ({ value }) => NoteCell(value),
      },
      {
        Header: "Action",
        Filter: ColumnFilter,
        disableFilters: true,
        width: 10,
        Cell: ({ row: { original } }) => (
          <Row>
            <Col sm={4}>
              <DownloadDocumentButton
                docName={original.fileName}
                url={`${url}/${original?.docId}`}
                fileName={original.fileName}
                headers={{ accept: "application/json" }}
              />
            </Col>
            <Col sm={4}>
              <ButtonWithTooltip
                variant="danger"
                onClick={() =>
                  deleteDocumentMutate.mutate({ docId: original.docId })
                }
                icon={<MdDelete />}
                tooltipText="Delete"
              />
            </Col>
          </Row>
        ),
      },
    ],
    []
  );

  return (
    <div className="card-body px-3">
      <div>
        <h4 style={{ textAlign: "left" }}>Document Upload :</h4>
        <div className="drop-zone-container" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag & drop files here, or click to select files</p>
        </div>

        {files.length > 0 && (
          <ul
            style={{
              textAlign: "left",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            {files.map((file) => (
              <li key={file.name}>
                {file.name}
                <Button
                  variant="link"
                  onClick={() => handleRemoveFile(file.name)}
                  style={{ marginLeft: "1rem" }}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        )}
        <Button
          className="my-4"
          onClick={handleUpload}
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? "Uploading..." : "Upload"}
        </Button>
      </div>
      <div>
        <h5 style={{ textAlign: "left" }}>File Name :</h5>
        {isLoading ? <Spinner /> : <TableComp columns={columns} data={data} />}
      </div>
    </div>
  );
}

export default DocumentModal;
