import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import FormField from "./FormField";
import SignatureCanvasField from "../SignatureCanvas/SignatureCanvasField";

function DriverRequiredFormField({
  firstName,
  lastName,
  errors,
  setValue,
  isLoading,
}) {
  return (
    <>
      <Row className="align-items-center mt-2">
        <Col xs={12} sm={6} md={4}>
          <FormField
            className="Field_label"
            title="First Name"
            id="firstName"
            ratio={[2, 10]}
          >
            <input
              type="text"
              className="form-control"
              value={firstName}
              disabled
            />
          </FormField>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <FormField
            className="Field_label"
            title="Last Name"
            id="lastName"
            ratio={[2, 10]}
          >
            <input
              type="text"
              className="form-control"
              value={lastName}
              disabled
            />
          </FormField>
        </Col>
      </Row>
      <SignatureCanvasField
        label="Digital Signature"
        signatureError={errors?.document?.message}
        onSignatureChange={(value) => setValue("document", value)}
      />
      <div className="d-flex justify-content-center mt-5">
        <button
          type="submit"
          className="credit-form-btn py-2"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Submit"}
        </button>
      </div>
    </>
  );
}

export default DriverRequiredFormField;
